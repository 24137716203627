import './App.css';
import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';


const App = () => {
  

  const date_to_name = {
    "02.08.25": "SF Ferry Market with Nancy, Vanessa, Olivia + Exploring Chinatown + Visiting Char's PoPo",
    "02.07.25": "Masquerade Ball at Lower School Mansion (+ Dungeon)",
    "02.06.25": 'Dress As Your Type" Spirit Day',
    "02.04.25": '"Rhyme Without Reason" Spirit Day',
    "02.01.25":"Ramen Dinner with Jennifer and Nancy",
    "01.27.25": "Ramen Broth Prep on a Monday",
    "01.23.25":"Kipper and Kola come to school",
    "01.20.25":"Cooking Miso Black Code (2 ways) for Jennifer and Nancy",
    "01.11.25":"James's Hockey Senior Night",
    "01.10.25":"Last Day of Whistler Trip + Banana Pancakes + Blowing...",
    "01.09.25":"Whistler Ski Day",
    "01.08.25":"Private Chef Japanese Dinner in Whistler + Ski Day",
   "01.07.25":"Making Pork Bao From Scratch in Whistler",
  "01.06.25":"First Ski Day in Whistler",
   "01.05.25":"Max Noodles & Tendon with James' Grandma + Driving to Whistler",
    "12.23.24":"Ferry Market + Cooking Duck Pasta",
  "11.27.24":"James Teaching Char to Skate in San Mateo",
   "11.14.24":"Cooking Shrimp Bowls",
   "11.08.24":"Half Moon Bay Part 3 + Chicken Pasta + Coconut Creme Brulee",
   "10.31.24":"Halloween Party (as Linearly Dependent Vectors)",
   "10.25.24":"Grocery Shopping at Dean's Produce + Cooking Greek Food + Drawing Portraits",
  "10.19.24":"First James Hockey Game for Char",
  "10.10.24":"First School Dance Together + Dinner & Creme Brulee",
    "10.03.24":"James' Yakitori Birthday Dinner",
   "09.28.24":"Nisei 12-Course Dinner with Anthony",
  "09.27.24":"Friday Morning Sunrise Run + Post-Run Omelettes",
   "09.25.24":"Ditching Wednesday Assembly for Suruki Supermarket Lunch in San Mateo Central Park",
  "09.21.24":"Nancy Tries James' Ramen",
  "09.20.24":"Post-Run Pancakes + Ramen Dinner in the Evening",
    "09.14.24":"Vista Point Sunset",
   "09.13.24":"Morning Run + Pumpkin Waffles",
   "09.06.24":"James and Kola",
  "08.31.24":"Making Dumplings from Scratch",
   "08.26.24":"James and the book 'James'",
   "08.23.24":"James and his Optics Class Telescope",
   "08.21.24":"First Week of School Santa Ramen Lunch",
   "08.12.24":"Eating Ravioli with Jennifer",
   "07.30.24":"Drawing Char's Portrait from Oxford",
   "07.27.24":"First Ramen Dinner",
   "07.25.24":"Half Moon Bay Part 2",
   "07.16.24":"Making Seafood Pasta from Scratch",
   "07.12.24":"Mori Point Hike in the Fog",
   "07.02.24": "First Meetup at Half Moon Bay",


  };

  const photoData = [
    {
      "date": "02.08.25",
      "photos": [
        "/photos/02.08.25/IMG_3905.jpeg",
        "/photos/02.08.25/IMG_3908.jpeg",
        "/photos/02.08.25/IMG_3909.jpeg",
        "/photos/02.08.25/IMG_3911.jpeg",
        "/photos/02.08.25/IMG_3915.jpeg",
        "/photos/02.08.25/IMG_3919.jpeg",
        "/photos/02.08.25/IMG_3920.jpeg",
        "/photos/02.08.25/IMG_3922.jpeg",
        "/photos/02.08.25/IMG_3926.jpeg",
        "/photos/02.08.25/IMG_3930.jpeg",
        "/photos/02.08.25/IMG_3934.jpeg",
        "/photos/02.08.25/IMG_3938.jpeg",
        "/photos/02.08.25/IMG_3939.jpeg"
      ]
    },
    {
      "date": "02.07.25",
      "photos": [
        "/photos/02.07.25/IMG_3869.jpeg",
        "/photos/02.07.25/IMG_3872.jpeg",
        "/photos/02.07.25/IMG_4585.jpeg"
      ]
    },
    {
      "date": "02.06.25",
      "photos": [
        "/photos/02.06.25/IMG_3829.jpeg",
        "/photos/02.06.25/IMG_3831.jpeg"
      ]
    },
    {
      "date": "02.04.25",
      "photos": [
        "/photos/02.04.25/IMG_0588.jpeg"
      ]
    },
    {
      "date": "02.01.25",
      "photos": [
        "/photos/02.01.25/IMG_3647.jpeg",
        "/photos/02.01.25/IMG_3719.jpeg",
        "/photos/02.01.25/IMG_3720.jpeg",
        "/photos/02.01.25/IMG_3721.jpeg",
        "/photos/02.01.25/IMG_3723.jpeg",
        "/photos/02.01.25/IMG_3725.jpeg",
        "/photos/02.01.25/IMG_3726.jpeg",
        "/photos/02.01.25/IMG_3727.jpeg",
        "/photos/02.01.25/IMG_3730.jpeg",
        "/photos/02.01.25/IMG_3732.jpeg",
        "/photos/02.01.25/IMG_3735.jpeg",
        "/photos/02.01.25/IMG_3738.jpeg",
        "/photos/02.01.25/IMG_3740.jpeg",
        "/photos/02.01.25/IMG_4556.jpeg"
      ]
    },
    {
      "date": "01.27.25",
      "photos": [
        "/photos/01.27.25/IMG_3638.jpeg",
        "/photos/01.27.25/IMG_3639.jpeg",
        "/photos/01.27.25/IMG_3642.jpeg",
        "/photos/01.27.25/IMG_3644.jpeg",
        "/photos/01.27.25/IMG_3645.jpeg",
        "/photos/01.27.25/IMG_4535.jpeg"
      ]
    },
    {
      "date": "01.23.25",
      "photos": [
        "/photos/01.23.25/IMG_3487.jpeg",
        "/photos/01.23.25/IMG_3489.jpeg",
        "/photos/01.23.25/IMG_3492.jpeg"
      ]
    },
    {
      "date": "01.20.25",
      "photos": [
        "/photos/01.20.25/IMG_3458.jpeg",
        "/photos/01.20.25/IMG_3459.jpeg",
        "/photos/01.20.25/IMG_3460.jpeg",
        "/photos/01.20.25/IMG_3462.jpeg"
      ]
    },
    {
      "date": "01.11.25",
      "photos": [
        "/photos/01.11.25/1:11:25.jpeg",
        "/photos/01.11.25/IMG_2977.JPG",
        "/photos/01.11.25/IMG_3334.jpeg",
        "/photos/01.11.25/IMG_6706.jpeg"
      ]
    },
    {
      "date": "01.10.25",
      "photos": [
        "/photos/01.10.25/1:10:25.jpeg",
        "/photos/01.10.25/IMG_3290.jpeg",
        "/photos/01.10.25/IMG_3292.jpeg",
        "/photos/01.10.25/IMG_3293.jpeg",
        "/photos/01.10.25/IMG_3296.jpeg",
        "/photos/01.10.25/IMG_3301.jpeg",
        "/photos/01.10.25/IMG_3303.jpeg",
        "/photos/01.10.25/IMG_3306.jpeg",
        "/photos/01.10.25/IMG_3312.jpeg",
        "/photos/01.10.25/IMG_3314.jpeg"
      ]
    },
    {
      "date": "01.09.25",
      "photos": [
        "/photos/01.09.25/1:9:25.jpeg",
        "/photos/01.09.25/IMG_3273.jpeg",
        "/photos/01.09.25/IMG_3285.jpeg",
        "/photos/01.09.25/IMG_3286.jpeg",
        "/photos/01.09.25/IMG_4422.jpeg"
      ]
    },
    {
      "date": "01.08.25",
      "photos": [
        "/photos/01.08.25/1:8:25IMG_2930.jpeg",
        "/photos/01.08.25/1:8:25IMG_2936.jpeg",
        "/photos/01.08.25/1:8:25IMG_3189.jpeg",
        "/photos/01.08.25/1:8:25IMG_3191.jpeg",
        "/photos/01.08.25/1:8:25IMG_3197.jpeg",
        "/photos/01.08.25/1:8:25IMG_3217.jpeg",
        "/photos/01.08.25/1:8:25IMG_3220.jpeg",
        "/photos/01.08.25/1:8:25IMG_3233.jpeg",
        "/photos/01.08.25/1:8:25IMG_3237.jpeg",
        "/photos/01.08.25/1:8:25IMG_3238.jpeg",
        "/photos/01.08.25/1:8:25IMG_3240.jpeg",
        "/photos/01.08.25/1:8:25IMG_3241.jpeg",
        "/photos/01.08.25/1:8:25IMG_3243.jpeg",
        "/photos/01.08.25/1:8:25IMG_3244.jpeg",
        "/photos/01.08.25/1:8:25IMG_3245.jpeg",
        "/photos/01.08.25/1:8:25IMG_3246.jpeg",
        "/photos/01.08.25/1:8:25IMG_3248.jpeg",
        "/photos/01.08.25/1:8:25IMG_3249.jpeg",
        "/photos/01.08.25/1:8:25IMG_3250.jpeg",
        "/photos/01.08.25/1:8:25IMG_3251.jpeg",
        "/photos/01.08.25/1:8:25IMG_3252.jpeg",
        "/photos/01.08.25/1:8:25IMG_3254.jpeg",
        "/photos/01.08.25/1:8:25IMG_3257.jpeg",
        "/photos/01.08.25/1:8:25IMG_3258.jpeg",
        "/photos/01.08.25/1:8:25IMG_3260.jpeg",
        "/photos/01.08.25/1:8:25IMG_3261.jpeg",
        "/photos/01.08.25/1:8:25IMG_6615.jpeg",
        "/photos/01.08.25/1:8:25IMG_6621.jpeg"
      ]
    },
    {
      "date": "01.07.25",
      "photos": [
        "/photos/01.07.25/1:7:25.jpeg",
        "/photos/01.07.25/IMG_2901.jpeg",
        "/photos/01.07.25/IMG_2904.jpeg",
        "/photos/01.07.25/IMG_3149.jpeg",
        "/photos/01.07.25/IMG_3151.jpeg",
        "/photos/01.07.25/IMG_3154.jpeg",
        "/photos/01.07.25/IMG_3156.jpeg",
        "/photos/01.07.25/IMG_3157.jpeg",
        "/photos/01.07.25/IMG_3159.jpeg",
        "/photos/01.07.25/IMG_3164.jpeg",
        "/photos/01.07.25/IMG_3171.jpeg",
        "/photos/01.07.25/IMG_4328.jpeg",
        "/photos/01.07.25/IMG_4332.jpeg",
        "/photos/01.07.25/IMG_4335.jpeg"
      ]
    },
    {
      "date": "01.06.25",
      "photos": [
        "/photos/01.06.25/1:6:25.jpeg",
        "/photos/01.06.25/IMG_2897.jpeg",
        "/photos/01.06.25/IMG_3128.jpeg",
        "/photos/01.06.25/IMG_3132.jpeg",
        "/photos/01.06.25/IMG_3137.jpeg",
        "/photos/01.06.25/IMG_4322.jpeg",
        "/photos/01.06.25/IMG_6580.jpeg"
      ]
    },
    {
      "date": "01.05.25",
      "photos": [
        "/photos/01.05.25/1:5:25IMG_3082.jpeg",
        "/photos/01.05.25/1:5:25IMG_3088.jpeg",
        "/photos/01.05.25/1:5:25IMG_3097.jpeg",
        "/photos/01.05.25/1:5:25IMG_3100.jpeg",
        "/photos/01.05.25/1:5:25IMG_3108.jpeg"
      ]
    },
    {
      "date": "12.23.24",
      "photos": [
        "/photos/12.23.24/12:23:24.jpeg",
        "/photos/12.23.24/IMG_2413.jpeg",
        "/photos/12.23.24/IMG_2417.jpeg",
        "/photos/12.23.24/IMG_2418.jpeg",
        "/photos/12.23.24/IMG_2424.jpeg",
        "/photos/12.23.24/IMG_2428.jpeg",
        "/photos/12.23.24/IMG_4165.jpeg",
        "/photos/12.23.24/IMG_4168.jpeg"
      ]
    },
    {
      "date": "11.27.24",
      "photos": [
        "/photos/11.27.24/11:27:24.jpeg",
        "/photos/11.27.24/IMG_1978.jpeg",
        "/photos/11.27.24/IMG_1981.jpeg"
      ]
    },
    {
      "date": "11.14.24",
      "photos": [
        "/photos/11.14.24/11:14:24IMG_1734.jpeg",
        "/photos/11.14.24/11:14:24IMG_1735.jpeg"
      ]
    },
    {
      "date": "11.08.24",
      "photos": [
        "/photos/11.08.24/11:8:24.jpeg",
        "/photos/11.08.24/IMG_1639.jpeg",
        "/photos/11.08.24/IMG_1640.jpeg",
        "/photos/11.08.24/IMG_1643.jpeg",
        "/photos/11.08.24/IMG_1644.jpeg",
        "/photos/11.08.24/IMG_1646.jpeg",
        "/photos/11.08.24/IMG_1647.jpeg"
      ]
    },
    {
      "date": "10.31.24",
      "photos": [
        "/photos/10.31.24/100_0559.jpeg",
        "/photos/10.31.24/10:31:24.JPG"
      ]
    },
    {
      "date": "10.25.24",
      "photos": [
        "/photos/10.25.24/10:25:24.jpeg",
        "/photos/10.25.24/IMG_1439.jpeg",
        "/photos/10.25.24/IMG_1444.jpeg",
        "/photos/10.25.24/IMG_1449.jpeg",
        "/photos/10.25.24/IMG_1450.jpeg",
        "/photos/10.25.24/IMG_1452.jpeg",
        "/photos/10.25.24/IMG_1454.jpeg"
      ]
    },
    {
      "date": "10.19.24",
      "photos": [
        "/photos/10.19.24/10:19:24.jpeg",
        "/photos/10.19.24/Screenshot 2025-01-20 at 3.30.41 PM.png"
      ]
    },
    {
      "date": "10.10.24",
      "photos": [
        "/photos/10.10.24/10:10:24.JPG",
        "/photos/10.10.24/IMG_1148.jpeg",
        "/photos/10.10.24/IMG_1149.jpeg",
        "/photos/10.10.24/IMG_1155.jpeg",
        "/photos/10.10.24/IMG_1156.jpeg",
        "/photos/10.10.24/IMG_1157.jpeg",
        "/photos/10.10.24/IMG_1158.jpeg",
        "/photos/10.10.24/IMG_1159.jpeg",
        "/photos/10.10.24/IMG_1168.JPG",
        "/photos/10.10.24/IMG_5553.jpeg",
        "/photos/10.10.24/IMG_7259.jpeg"
      ]
    },
    {
      "date": "10.03.24",
      "photos": [
        "/photos/10.03.24/10:3:24.jpeg",
        "/photos/10.03.24/IMG_0980.jpeg",
        "/photos/10.03.24/IMG_0981.jpeg",
        "/photos/10.03.24/IMG_0984.jpeg",
        "/photos/10.03.24/IMG_0988.jpeg",
        "/photos/10.03.24/IMG_0989.jpeg",
        "/photos/10.03.24/IMG_0990.jpeg",
        "/photos/10.03.24/IMG_0991.jpeg",
        "/photos/10.03.24/IMG_0992.jpeg",
        "/photos/10.03.24/IMG_0998.jpeg",
        "/photos/10.03.24/IMG_0999.jpeg",
        "/photos/10.03.24/IMG_5510.jpeg",
        "/photos/10.03.24/IMG_5515.jpeg"
      ]
    },
    {
      "date": "09.28.24",
      "photos": [
        "/photos/09.28.24/9:28:24.jpeg",
        "/photos/09.28.24/IMG_0896.jpeg",
        "/photos/09.28.24/IMG_0898.jpeg",
        "/photos/09.28.24/IMG_0901.jpeg",
        "/photos/09.28.24/IMG_0902.jpeg",
        "/photos/09.28.24/IMG_0907.jpeg",
        "/photos/09.28.24/IMG_3600.jpeg",
        "/photos/09.28.24/IMG_3601.jpeg",
        "/photos/09.28.24/IMG_3602.jpeg",
        "/photos/09.28.24/IMG_3603.jpeg",
        "/photos/09.28.24/IMG_3604.jpeg",
        "/photos/09.28.24/IMG_3605.jpeg",
        "/photos/09.28.24/IMG_3606.jpeg",
        "/photos/09.28.24/IMG_3607.jpeg",
        "/photos/09.28.24/IMG_3608.jpeg",
        "/photos/09.28.24/IMG_3609.jpeg",
        "/photos/09.28.24/IMG_3610.jpeg",
        "/photos/09.28.24/IMG_3611.jpeg",
        "/photos/09.28.24/IMG_3612.jpeg",
        "/photos/09.28.24/IMG_5481.jpeg"
      ]
    },
    {
      "date": "09.27.24",
      "photos": [
        "/photos/09.27.24/9:27:24.jpeg",
        "/photos/09.27.24/IMG_0880.jpeg",
        "/photos/09.27.24/IMG_0882.jpeg"
      ]
    },
    {
      "date": "09.25.24",
      "photos": [
        "/photos/09.25.24/9:25:24.jpeg",
        "/photos/09.25.24/IMG_0850.jpeg"
      ]
    },
    {
      "date": "09.21.24",
      "photos": [
        "/photos/09.21.24/9:21:24.jpeg",
        "/photos/09.21.24/IMG_0719.jpeg",
        "/photos/09.21.24/IMG_0725.jpeg",
        "/photos/09.21.24/IMG_3562.jpeg"
      ]
    },
    {
      "date": "09.20.24",
      "photos": [
        "/photos/09.20.24/9:20:24.jpeg",
        "/photos/09.20.24/IMG_0699.jpeg",
        "/photos/09.20.24/IMG_0701.jpeg",
        "/photos/09.20.24/IMG_0703.jpeg",
        "/photos/09.20.24/IMG_0711.jpeg",
        "/photos/09.20.24/IMG_0714.jpeg"
      ]
    },
    {
      "date": "09.14.24",
      "photos": [
        "/photos/09.14.24/9:14:24.jpeg",
        "/photos/09.14.24/IMG_0650.jpeg",
        "/photos/09.14.24/IMG_0652.jpeg",
        "/photos/09.14.24/IMG_0654.jpeg",
        "/photos/09.14.24/IMG_3522.jpeg"
      ]
    },
    {
      "date": "09.13.24",
      "photos": [
        "/photos/09.13.24/9:13:24.jpeg",
        "/photos/09.13.24/IMG_0604.jpeg",
        "/photos/09.13.24/IMG_0607.jpeg",
        "/photos/09.13.24/IMG_0608.jpeg",
        "/photos/09.13.24/IMG_0610.jpeg"
      ]
    },
    {
      "date": "09.06.24",
      "photos": [
        "/photos/09.06.24/9:6:24.jpeg"
      ]
    },
    {
      "date": "08.31.24",
      "photos": [
        "/photos/08.31.24/8:31:24.jpeg",
        "/photos/08.31.24/IMG_0456.jpeg",
        "/photos/08.31.24/IMG_0461.jpeg",
        "/photos/08.31.24/IMG_0463.jpeg",
        "/photos/08.31.24/IMG_0465.jpeg",
        "/photos/08.31.24/IMG_0466.jpeg",
        "/photos/08.31.24/IMG_0467.jpeg",
        "/photos/08.31.24/IMG_0474.jpeg"
      ]
    },
    {
      "date": "08.26.24",
      "photos": [
        "/photos/08.26.24/8:26:24.jpeg",
        "/photos/08.26.24/IMG_0268.jpeg",
        "/photos/08.26.24/IMG_0269.jpeg"
      ]
    },
    {
      "date": "08.23.24",
      "photos": [
        "/photos/08.23.24/8:23:24.jpeg"
      ]
    },
    {
      "date": "08.21.24",
      "photos": [
        "/photos/08.21.24/8:21:24.jpeg"
      ]
    },
    {
      "date": "08.12.24",
      "photos": [
        "/photos/08.12.24/8:12:24.jpeg",
        "/photos/08.12.24/IMG_9708.jpeg",
        "/photos/08.12.24/IMG_9710.jpeg"
      ]
    },
    {
      "date": "07.30.24",
      "photos": [
        "/photos/07.30.24/7:30:24.jpeg",
        "/photos/07.30.24/IMG_8620.jpeg"
      ]
    },
    {
      "date": "07.27.24",
      "photos": [
        "/photos/07.27.24/7:27:24.jpeg",
        "/photos/07.27.24/IMG_4737.jpeg",
        "/photos/07.27.24/IMG_8488.jpeg",
        "/photos/07.27.24/IMG_8490.jpeg"
      ]
    },
    {
      "date": "07.25.24",
      "photos": [
        "/photos/07.25.24/7:25:247:25:24.jpeg",
        "/photos/07.25.24/7:25:24IMG_8461.jpeg",
        "/photos/07.25.24/7:25:24IMG_8462.jpeg",
        "/photos/07.25.24/7:25:24IMG_8466.jpeg",
        "/photos/07.25.24/7:25:24IMG_8467.jpeg"
      ]
    },
    {
      "date": "07.16.24",
      "photos": [
        "/photos/07.16.24/7:16:24.jpeg",
        "/photos/07.16.24/IMG_4838.jpeg",
        "/photos/07.16.24/IMG_8347.jpeg",
        "/photos/07.16.24/IMG_8349.jpeg",
        "/photos/07.16.24/IMG_8356.jpeg",
        "/photos/07.16.24/IMG_8357.jpeg",
        "/photos/07.16.24/IMG_8359.jpeg",
        "/photos/07.16.24/Screenshot 2025-01-20 at 3.13.08 PM.png",
        "/photos/07.16.24/Screenshot 2025-01-20 at 3.13.14 PM.png"
      ]
    },
    {
      "date": "07.12.24",
      "photos": [
        "/photos/07.12.24/7:12:24.jpeg",
        "/photos/07.12.24/IMG_8330.PNG",
        "/photos/07.12.24/IMG_8336.jpeg"
      ]
    },
    {
      "date": "07.02.24",
      "photos": [
        "/photos/07.02.24/7:2:24.jpeg",
        "/photos/07.02.24/IMG_3148.jpeg",
        "/photos/07.02.24/IMG_8248.jpeg",
        "/photos/07.02.24/IMG_8252.jpeg"
      ]
    }
  ]


  const [selectedImage, setSelectedImage] = useState(null);
  const adventureListRef = useRef(null); // Reference to the adventure list
  const photoGridRefs = useRef([]); // To track each photo grid
  const [showNavbar, setShowNavbar] = useState(false);

  const scrollToPhotos = () => {
    adventureListRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToGrid = (index) => {
    photoGridRefs.current[index].scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const firstGridPosition = photoGridRefs.current[0]?.getBoundingClientRect().top;
      setShowNavbar(firstGridPosition <= window.innerHeight / 2); // Show navbar when first grid is near top
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  
  const handleImageClick = (url) => {
    setSelectedImage(url);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const [showNote, setShowNote] = useState(false);
  const SECRET_PASSWORD = "yourpassword"; // Change this to your actual password
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState("");
  
  const handlePasswordSubmit = () => {
    if (password === "password") {  // Simple check
      setIsAuthenticated(true);
      setError("");
    } else {
      setError("Incorrect password. Try again.");
    }
  };


  return (
    <div className="app">
    {/* Conditional Sticky Navbar */}
    {showNavbar && (
      <nav className="sticky-navbar">
        {/* <img src="jandclogo.png" alt="and" className="logo" /> */}

        {/* <h3>James + Charlotte | Digital Bucket-List Log</h3> */}
        <ul className="navbar-links">
          {photoData.map((entry, index) => (
            <li key={index}>
              <button onClick={() => scrollToGrid(index)}>{entry.date}</button>
            </li>
          ))}
        </ul>
      </nav>
    )}

      <div className="banner">
        <header className="header">
          
          <div className="header-logo-container">
            
            <h1 className="header-title">
              
              James <img src="jandclogo.png" alt="and" className="logo" /> Charlotte
            </h1>
          </div>
          
          <h2>Documenting our <em>bucket list</em> adventures!</h2>
          <p>Happy Valentines Day, James! Love you so so much!</p>
          
           {/* <button onClick={() => setIsModalOpen(true)}>Open Modal</button> */}



         
          
          <button className="scroll-down-btn" onClick={scrollToPhotos}>
            <FontAwesomeIcon icon={faArrowDown} />
          </button>
        </header>

        
        
        
      </div>
{/* 
      {isModalOpen && (
      <div className="modal-overlay">
        <div className="modal">
          <h2>Enter Password</h2>
          {!isAuthenticated ? (
            <>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
              />
              <button onClick={handlePasswordSubmit}>Submit</button>
              {error && <p className="error">{error}</p>}
            </>
          ) : (
            <p>✅ Access granted! Secret message here.</p>
          )}
          <button onClick={() => setIsModalOpen(false)}>Close</button>
        </div>
      </div>
    )} */}



<div className="adventure-list" ref={adventureListRef}>
  {photoData.map((entry, index) => (
    <div
      key={index}
      className="photo-grid"
      ref={(el) => (photoGridRefs.current[index] = el)}
    >
      <h3>{entry.date} - {date_to_name[entry.date]}</h3> {/* Display date with title */}
      <div className="photo-grid-images">
        {entry.photos.map((photo, idx) => (
          <img
            key={idx}
            className="photo-grid-image"
            src={photo}
            alt={`Photo ${idx + 1}`}
            onClick={() => handleImageClick(photo)}
          />
        ))}
      </div>
    </div>
  ))}
</div>

      {/* <div className="adventure-list" ref={adventureListRef}>
        {photoData.map((entry, index) => (
          <div
            key={index}
            className="photo-grid"
            ref={(el) => (photoGridRefs.current[index] = el)}
          >
            <h3>{entry.date}</h3>
            <div className="photo-grid-images">
              {entry.photos.map((photo, idx) => (
                <img
                  key={idx}
                  className="photo-grid-image"
                  src={photo}
                  alt={`Photo ${idx + 1}`}
                  onClick={() => handleImageClick(photo)}
                />
              ))}
            </div>
          </div>
        ))}
      </div> */}

      {/* Footer */}
      <footer className="footer">
        <img src="jandclogo.png" alt="and" className="logo" />
        <p>© 2025 James + Charlotte's Adventure Log</p>
        <button onClick={() => setShowNote(!showNote)}>
            {showNote ? "Hide Notes" : "Past Notes"}
          </button>

          {showNote && (
            <p>
              Happy 6-months, James! I made this website so that we can
              document all of our adventures (especially since Apple photos is
              really letting us down with the non-ordered shared album...sigh).
              I'll keep building out this site so perhaps we can use it as a hub for photos, journaling,
              thoughts/thought-experiments, drawings, post-marathon-running-or-jiujitsu-night dream logs, <em>Charred</em> cooking
              successes, movie reviews, travel vlogs (yes, including our INCREDIBLE cold plunge content), and silliness and
              "ahhhhhhhh"s and jamesiness and charlottian sensations and more. (And who knows? Maybe I'll embed some <a href="https://youtu.be/C7tydu_erZo?si=eG1XFr1jWxXB6gTX">secret messages</a> to you on this site...test your computer security skills perhaps!) Already, in 6 months, we've shared so many beautiful moments
              together. So, together, let's keeping making more... ;) Love you so so much. -Char
            </p>
          )}
      </footer>

      {/* Modal */}
      {selectedImage && (
        <div className="modal show" onClick={closeModal}>
          <img src={selectedImage} alt="Selected" />
        </div>
      )}
    </div>
  );
};

export default App;